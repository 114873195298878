import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth-guard.service';
import { LoginShow } from './guards/login-show.service';

const routes: Routes = [
	{ path: 'login',canActivate: [LoginShow], loadChildren: () => import('./login/login.module').then(m => m.LoginModule) }, 
	{ path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) }, 
	{ path: 'website',canActivate: [AuthGuard], loadChildren: () => import('./website/website.module').then(m => m.WebsiteModule) }, 
	{ path: 'websetup',canActivate: [AuthGuard], loadChildren: () => import('./websetup/websetup.module').then(m => m.WebsetupModule) },
	{ path: 'admin',canActivate: [AuthGuard], loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
	{ path: 'form-builder', loadChildren: () => import('./form-builder/form-builder.module').then(m => m.FormBuilderModule) },
	{ path: 'change', loadChildren: () => import('./change/change.module').then(m => m.ChangeModule) },
	{ path: 'bts',canActivate: [AuthGuard], loadChildren: () => import('./bts/bts.module').then(m => m.BtsModule) },
	{ path: 'renu', loadChildren: () => import('./renu/renu.module').then(m => m.RenuModule) },
	{ path: 'aap', loadChildren: () => import('./aap/aap.module').then(m => m.AapModule) },
	{ path: '', redirectTo: '/login', pathMatch : "full" },
	{ path: 'sc', loadChildren: () => import('./sc/sc.module').then(m => m.ScModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
