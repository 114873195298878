import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class DataService {
	websites: any = [];
	loginData: any = [];
	userData: any = [];
	constructor(private router: Router,private cookieservice: CookieService) {
		
	}
	tologin() : any{
		this.cookieservice.delete('token');
		this.cookieservice.delete('userid');
		this.router.navigate(['/login']);
	}
}
