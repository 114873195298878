import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { DataService } from './data.service';


@Injectable({
  providedIn: 'root'
})
export class ApiService{
  
  //apiURL: string = 'http://localhost/cmsBack';
  apiURL: string = 'https://www.fidgroupcms.com/fidscms';

  constructor(private httpClient: HttpClient,private cookieservice: CookieService,private dataservice: DataService) {}

  public createpost(urltail: string, postdata: any)
  {
    //console.log(urltail);
    if(urltail == 'website/savecontent' || urltail == 'website/uploadimage') {
      if(this.dataservice.userData['access']=='Without') {
        postdata['token'] = this.cookieservice.get('token');
        return this.httpClient.post(`${this.apiURL}/`+urltail+`/`,postdata);
      } else {
        let data = {'userid': this.cookieservice.get('userid'),'token': this.cookieservice.get('token'),'data': postdata,'url': urltail};
        return this.httpClient.post(`${this.apiURL}/`+'website/changerequest'+`/`,data);
      }
    } else {
      postdata['token'] = this.cookieservice.get('token');
      return this.httpClient.post(`${this.apiURL}/`+urltail+`/`,postdata);
    }
  }
  public loginReq(urltail: string, postdata: any)
  {
      postdata['token'] = this.cookieservice.get('token');
      return this.httpClient.post(`${this.apiURL}/`+urltail+`/`,postdata);
  }

  public getdata(urltail: string)
  {
  	return this.httpClient.post(`${this.apiURL}/`+urltail+`/`,{'token' : this.cookieservice.get('token')});
  }

  public checkToken() {
    if(this.cookieservice.get('token') && this.cookieservice.get('userid')) {
      return true;
    } else {
      return false;
    }
  }
}
