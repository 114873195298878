import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private apiservice: ApiService) { }

  isAuthenticated() : any {
     return this.apiservice.checkToken();
  }
}
